:root {
  --font-1: "AlegreyaSans-Bold";
  --font-2: "AlegreyaSans-Regular";
  --font-3: "Colby-Regular";
  --font-4: "Colby-Extended-Regular";
  --font-5: "Colby-Compressed-Regular";
}

@font-face {
  font-family: AlegreyaSans-Bold;
  src: url("../../clients/#{$client}/fonts/AlegreyaSans-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: AlegreyaSans-Regular;
  src: url("../../clients/#{$client}/fonts/AlegreyaSans-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: Colby-Regular;
  src: url("../../clients/#{$client}/fonts/Colby-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: Colby-Extended-Regular;
  src: url("../../clients/#{$client}/fonts/Colby-Extended-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: Colby-Compressed-Regular;
  src: url("../../clients/#{$client}/fonts/Colby-Compressed-Regular.ttf")
    format("truetype");
}
