:root {
  --focus-text-color: #00B254;
  --focus-ring-color: #C0FF45;
}.base {
  
  
  background-color: #FFFFFF;
}
.baseWhite {
  
  
  background-color: #FFFFFF;
}
.baseRounded {
  
  
  border-radius: 5px;
  background-color: #FFFFFF;
}
.baseRoundedBlack {
  &:active {
    border-color: #00B254;
  }
  
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;
  background-color: #FFFFFF;
}
.baseRoundedAlt {
  
  
  border-radius: 20px;
  background-color: #FFFFFF;
}
.baseRoundedThick {
  &:active {
    border-color: #00B254;
  }
  
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;
  background-color: #FFFFFF;
}
.baseBorderedBlack {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #222222;
  background-color: #FFFFFF;
}
.baseBorderedBlackRounded {
  
  
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #222222;
  background-color: #FFFFFF;
}
.accentDarkRounded {
  &:active {
    border-color: #00B254;
  }
  
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
  background-color: #00B254;
}
.baseGray {
  
  
  background-color: #808080;
}
.backgroundGray {
  
  
  background-color: #F4F4F4;
}
.lightGray {
  
  
  background-color: #F4F4F4;
}
.accentDark {
  
  
  background-color: #00B254;
}
.accentLight {
  
  
  background-color: #C0FF45;
}
.accentDarkBorderedClear {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
}
.accentDarkBorderedWhite {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
  background-color: #FFFFFF;
}
.baseBorderedClear {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #808080;
}
.roundedBase {
  
  
  border-radius: 20px;
  background-color: #F4F4F4;
}
.roundedaccentDarkBordered {
  
  
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
  background-color: #00B254;
}
.roundedAccentDark {
  
  
  border-radius: 50px;
  border-width: 3px;
  border-style: solid;
  border-color: #00B254;
  background-color: #00B254;
}
.roundedBaseBlackBordered {
  
  
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #222222;
}
.roundedaccentDark {
  
  
  border-radius: 5px;
  background-color: #00B254;
}
.circleClear {
  
  
  border-radius: 100px;
}
.circleBaseBordered {
  &:active {
    border-color: #808080;
  }
  &:disabled {
    border-color: #808080;
  }
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #808080;
  background-color: #FFFFFF;
}
.circleBaseBorderedAlt {
  &:active {
    border-color: #00B254;
  }
  &:disabled {
    border-color: #00B254;
  }
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
  background-color: #00B254;
}
.circleaccentDark {
  
  
  border-radius: 100px;
  background-color: #00B254;
}
.circleaccentDarkThick {
  &:active {
    border-color: #C0FF45;
  }
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #00B254;
  background-color: #00B254;
}
.circleAccentLight {
  
  
  border-radius: 100px;
  background-color: #00B254;
}.title1 {font-family: "Colby-Regular";
  font-size: 1.375rem;
  font-weight: 700;}
.title2 {font-family: "Colby-Regular";
  font-size: 1.25rem;
  font-weight: 700;}
.title3 {font-family: "Colby-Regular";
  font-size: 1.125rem;
  font-weight: 700;}
.title4 {font-family: "Colby-Regular";
  font-size: 0.875rem;
  font-weight: 600;}
.title5 {font-family: "Colby-Regular";
  font-size: 1.5rem;
  font-weight: 700;}
.subtitle1 {font-family: "Colby-Regular";
  font-size: 1.625rem;
  font-weight: 400;}
.subtitle2 {font-family: "Colby-Regular";
  font-size: 1.375rem;
  font-weight: 400;}
.buttontext1 {font-family: "Colby-Regular";
  font-size: 1.625rem;
  font-weight: 400;}
.body1 {font-family: "Colby-Regular";
  font-size: 1rem;
  font-weight: 400;}
.body2 {font-family: "Colby-Regular";
  font-size: 0.875rem;
  font-weight: 400;}
.body3 {font-family: "Colby-Regular";
  font-size: 0.75rem;
  font-weight: 500;}
.body4 {font-family: "Colby-Regular";
  font-size: 0.75rem;
  font-weight: 400;}.title1_baseBlack {@extend .title1;  color: #222222;
  text-transform: uppercase;}
.title1_baseLink {@extend .title1;  color: #FF8500;
  text-transform: uppercase;}
.title1_baseWhite {@extend .title1;  color: #FFFFFF;
  text-transform: uppercase;}
.title1_baseGray {@extend .title1;  color: #808080;
  text-transform: uppercase;}
.title1_accentDark {@extend .title1;  color: #00B254;
  text-transform: uppercase;}
.title2_baseBlack {@extend .title2;  color: #222222;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_baseGray {@extend .title2;  color: #808080;
  text-transform: uppercase;}
.title2_baseLink {@extend .title2;  color: #FF8500;
  text-transform: uppercase;}
.title2_accentDark {@extend .title2;  color: #00B254;
  text-transform: uppercase;}
.title3_baseBlack {@extend .title3;  color: #222222;
  text-transform: uppercase;}
.title3_baseWhite {@extend .title3;  color: #FFFFFF;
  text-transform: uppercase;}
.title3_baseGray {@extend .title3;  color: #808080;
  text-transform: uppercase;}
.title3_baseLink {@extend .title4;  color: #FF8500;
  text-transform: uppercase;}
.title3_accentDark {@extend .title3;  color: #00B254;
  text-transform: uppercase;}
.title4_baseBlack {@extend .title4;  color: #222222;
  text-transform: uppercase;}
.title4_baseWhite {@extend .title4;  color: #FFFFFF;
  text-transform: uppercase;}
.title4_baseGray {@extend .title4;  color: #808080;
  text-transform: uppercase;}
.title4_accentDark {@extend .title4;  color: #00B254;
  text-transform: uppercase;}
.title4_baseLink {@extend .title4;  color: #FF8500;
  text-transform: uppercase;}
.title5_baseBlack {@extend .title5;  color: #222222;
  text-transform: uppercase;}
.title5_baseLink {@extend .title4;  color: #FF8500;
  text-transform: uppercase;}
.title5_baseWhite {@extend .title5;  color: #FFFFFF;
  text-transform: uppercase;}
.title5_baseGray {@extend .title5;  color: #808080;
  text-transform: uppercase;}
.title5_accentDark {@extend .title5;  color: #00B254;
  text-transform: uppercase;}
.body1_baseBlack {@extend .body1;  color: #222222;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.body1_baseGray {@extend .body1;  color: #808080;
  text-transform: initial;}
.body1_baseWarning {@extend .body1;  color: #FF2A00;
  text-transform: initial;}
.body1_baseLink {@extend .body1;  color: #FF8500;
  text-transform: initial;}
.body1_accentDark {@extend .body1;  color: #00B254;
  text-transform: initial;}
.body2_baseBlack {@extend .body2;  color: #222222;
  text-transform: initial;}
.body2_baseWhite {@extend .body2;  color: #FFFFFF;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #808080;
  text-transform: initial;}
.body2_accentDark {@extend .body2;  color: #00B254;
  text-transform: initial;}
.subtitle2_accentDark {@extend .subtitle2;  color: #00B254;
  text-transform: initial;}
.buttontext1_baseBlack {@extend .buttontext1;  color: #222222;
  text-transform: initial;}
.buttontext1_lightGray {@extend .buttontext1;  color: #F4F4F4;
  text-transform: initial;}.buttonPrimaryStandard {
  &:active {
    @extend .title2_baseWhite;
background-color: #00B254;
  }
  
  @extend .title2_baseWhite;
background-color: #00B254;
@extend .baseRoundedAlt;
}
.buttonPrimaryStandardAlt {
  &:active {
    @extend .title2_baseWhite;
background-color: #00B254;
  }
  
  @extend .title2_baseWhite;
background-color: #FFFFFF;
@extend .roundedaccentDark;
}
.buttonMobileSelect {
  &:active {
    @extend .title2_baseWhite;
background-color: #C0FF45;
  }
  
  @extend .title2_baseWhite;
@extend .roundedBaseBlackBordered;
}
.buttonPackingItem {
  &:active {
    @extend .title2_baseWhite;
background-color: #00B254;
  }
  
  @extend .title2_accentDark;
background-color: #FFFFFF;
@extend .circleaccentDarkThick;
}
.buttonQuantitySelect {
  &:active {
    @extend .buttontext1_lightGray;
  }
  
  @extend .buttontext1_baseBlack;
@extend .base;
}
.buttonPrimaryBase {
  &:active {
    @extend .title4_accentDark;
background-color: #F4F4F4;
  }
  
  @extend .title4_accentDark;
background-color: #FFFFFF;
@extend .base;
}
.buttonPrimaryClear {
  &:active {
    @extend .title4_baseLink;
  }
  
  @extend .title4_baseLink;
}
.buttonPrimaryClearAlt {
  &:active {
    @extend .title4_baseLink;
  }
  
  @extend .title4_baseLink;
}
.buttonPrimaryClearBlack {
  &:active {
    @extend .title4_baseBlack;
  }
  
  @extend .title4_baseBlack;
}
.buttonPrimaryClearGray {
  &:active {
    @extend .title4_baseGray;
  }
  
  @extend .title4_baseGray;
}
.buttonAccentLightRounded {
  
  
  @extend .title4_baseWhite;
background-color: #00B254;
@extend .roundedAccentDark;
}
.buttonPrimaryClearBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #F4F4F4;
  }
  
  @extend .title2_baseBlack;
@extend .circleBaseBordered;
}
.buttonNegConfirm {
  &:active {
    @extend .title2_baseBlack;
  }
  
  @extend .title2_baseBlack;
@extend .baseBorderedBlackRounded;
}
.buttonConfirm {
  &:active {
    @extend .title2_baseWhite;
  }
  
  @extend .title2_baseWhite;
@extend .accentDarkRounded;
}
.buttonNavStandard {
  &:active {
    @extend .title3_accentDark;
background-color: #C0FF45;
  }
  
  @extend .title3_baseWhite;
background-color: #00B254;
@extend .baseRoundedAlt;
}
.buttonNavSecondary {
  &:active {
    @extend .title3_accentDark;
background-color: #C0FF45;
  }
  
  @extend .title3_baseWhite;
background-color: #00B254;
@extend .baseRoundedAlt;
}
.buttonSecondaryBordered {
  &:active {
    @extend .title1_baseWhite;
background-color: #00B254;
  }
  
  @extend .title1_baseWhite;
@extend .roundedaccentDarkBordered;
}
.buttonTertiaryBordered {
  &:active {
    @extend .body1_baseWhite;
background-color: #00B254;
  }
  
  @extend .body1_baseGray;
background-color: #FFFFFF;
@extend .accentDarkBorderedWhite;
}
.buttonSecondary {
  &:active {
    @extend .body1_baseWarning;
  }
  
  @extend .body1_baseGray;
@extend .baseRounded;
}
.buttonSecondaryBorderless {
  
  
  @extend .body1_baseGray;
}
.buttonSecondaryBorderlessAlt {
  &:active {
    background-color: #00B254;
  }
  
  @extend .body1_baseGray;
@extend .baseBorderedBlackRounded;
}
.buttonSecondaryBorderlessAccent {
  
  
  @extend .body1_baseLink;
}
.buttonSecondaryLink {
  
  
  @extend .body1_baseGray;
}
.buttonTertiaryLink {
  
  
  @extend .body1_baseGray;
}.primary {
@extend .body1_baseGray;
 &::placeholder {
    @extend .body1_baseGray;
  }
}
.secondary {
@extend .body1_baseGray;
 &::placeholder {
    @extend .body1_baseGray;
  }
}
.tertiary {
@extend .body1_baseBlack;
 &::placeholder {
    @extend .body1_baseBlack;
  }
}
.quaternary {
@extend .body1_baseBlack;
 &::placeholder {
    @extend .body1_baseBlack;
  }
}